<template>
  <div>
    <Header></Header>
    <div class="content">
      <swiper :options="swiperOption" ref="mySwiper" v-if="bannerList.length > 0">
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <img :src="oss + item" class="img_qa" alt="" />
        </swiper-slide>
      </swiper>
      <!-- <img src="../assets/static/images/img_mycir1.png" class="cir_bg" alt="" /> -->
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3 m1440">
          当前位置：<router-link to="/">首页</router-link>><a href="javascript:;">我的证书</a>
        </div>
      </div>
      <div v-if="cert_list.length <= 0 && is_load == true">
        <div class="no_data">
          <img src="../assets/static/icon/z_nothing.png" class="z_nothing" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
      <div class="my_cir m1440" v-else>
        <div class="fs32 tc pt60">我的证书</div>

        <div v-if="settingType.settingType == 0">
          <ul class="cir_list flex-box flex-col-start flex-wrap">
            <li v-for="(item, index) in cert_list" :key="index">
              <img src="../assets/static/images/certificate.png" class="certificate" alt="" />
              <div class="pt30 pb20 flex-box flex-between">
                <div class="flex-grow-1 cer_title multi-line fs18">{{ item.certSummary?item.certSummary:'环保桥技术证书' }}</div>
                <a href="javascript:;" class="download fs16" @click="downLoadFile(index)">下载证书</a>
              </div>
              <div class="multi-line col9">
                {{ item.createTime }}
            </div>
            </li>
            <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
            <!-- <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination> -->
          </ul>
        </div>
        <div v-else>
          <ul class="cir_list flex-box flex-col-start flex-wrap">
            <li v-for="(item, index) in cert_list" :key="index">
              <!-- <img src="../assets/static/images/certificate.png" class="certificate" alt="" /> -->
              <div class="relative" style="height: 300px; overflow: hidden">
                <div class="certificate flex-box" style="transform: scale(0.3); width: 1400px; position: absolute; top: 0;
                  left: 0;
                  transform-origin: 1% 45%;
                ">
                  <div class="cer_left flex-box flex-col">
                    <div class="left_top flex-center flex-box">
                      <div>
                        <div class="fs16">THE INTERNATIONAL</div>
                        <div class="fs21">REC STANDARD</div>
                      </div>
                    </div>
                    <div class="left_center flex-grow-1">
                      <div class="cola9 fs10">
                        This Redemption Statement has been produced for
                      </div>
                      <div class="fs22" v-if="item.Certificate">
                        {{
                          item.Certificate.companyNameEn
                          ? item.Certificate.companyNameEn
                          : ""
                        }}
                      </div>
                      <div class="cola9 fs10 pb10">by</div>
                      <div class="fs18">CLIMATE BRIDGE(SHANGHAI)LTD</div>
                      <div class="cola9 fs10">confirming the Redemption of</div>
                      <div class="fs30 ptb10">{{ item.Number_of_Certificates }}</div>
                      <div class="cola9 fs10 pb20">
                        I-REC Certificates,representing
                        {{ item.Number_of_Certificates }} MWh of electricity generated from
                        renewable sources
                      </div>
                      <div class="cola9 fs10">
                        This Statement relates to electricityconsumption located at orin
                      </div>
                      <div class="fs18 ptb5" v-if="item.Certificate">
                        {{ item.Certificate.addressEn }}
                      </div>
                      <div class="fs18 ptb5" v-if="item.Certificate">
                        {{ item.Certificate.country }}
                      </div>
                      <div class="cola9 fs10 pb20">in respect of the reporting period</div>
                      <div class="fs22 pb10">
                        {{ item.BeginPurchaseDate }} <span class="fs18">to</span>
                        {{ item.EndPurchaseDate }}
                      </div>

                      <div class="cola9 fs10 pb10">The stated Redemption Purpose is</div>
                      <div class="fs18 pb10 pb30">{{ item.usage }}</div>
                      <img src="../assets/static/icon/icon_logo_cer.png" class="icon_logo_cer" alt="" />
                      <!-- {{item.qrCode}} -->
                      <div class="evident">Evident</div>
                    </div>
                    <div class="code_box flex-box">
                      <!-- <vue-qr :text="url + detail.VerificationUrl"></vue-qr> -->
                      <img v-if="item.qrCode" :src="oss + item.qrCode" alt="" />
                      <div>
                        <div class="fs24">QR Code Verification</div>
                        <div class="fs12 ptb10">
                          Verify the status of this Redemption Statement by scanning the QR
                          code on the left and entering in the Verification Key below
                        </div>
                        <div class="fs24">Verification Key</div>
                        <div class="fs24 pt10 key">{{ item.VerificationKey }}</div>
                        <a class="fs12 pt10 colf" target="_blank" style="width: 490px; display: block"
                          :href="url + detail.VerificationUrl">
                          {{ url + detail.VerificationUrl }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="cer_right flex-box flex-col tc">
                    <div class="right_item fs20 fwb flex-box flex-center">
                      Redeemed Certificates
                    </div>
                    <div class="border">
                      <div class="right_item fs16 flex-box flex-center fwb">
                        Production Device Details
                      </div>
                      <div class="right_item fs12 flex-box flex-center fwb">
                        <div class="flex-grow-1">Device</div>
                        <div class="flex-grow-1">Country of Origin</div>
                        <div class="flex-grow-1">Energy Source</div>
                        <div class="flex-grow-1">Technology</div>
                        <div class="flex-grow-1">Supported</div>
                        <div class="flex-grow-1">Commisioning Date</div>
                        <div class="flex-grow-1">Carbon(CO2 /MWh)</div>
                      </div>
                      <div class="right_item fs12 flex-box flex-center">
                        <div class="flex-grow-1">{{ item.Device }}</div>
                        <div class="flex-grow-1">{{ item.Country_of_Origin }}</div>
                        <div class="flex-grow-1">{{ item.Energy_Source }}</div>
                        <div class="flex-grow-1">{{ item.Technology }}</div>
                        <div class="flex-grow-1">{{ item.Supported }}</div>
                        <div class="flex-grow-1">{{ item.Commisioning_Date }}</div>
                        <div class="flex-grow-1">{{ item.Co2_produced }}</div>
                      </div>
                      <div class="right_item fs16 flex-box flex-center fwb">
                        Redeemed Certificates
                      </div>
                      <div class="right_item fs12 flex-box flex-center fwb">
                        <div class="pertwenty">From CertificateID</div>
                        <div class="pertwenty">To Certificate ID</div>
                        <div class="flex-grow-1">Number of Certificates</div>
                        <div class="flex-grow-1">Offset Attributes</div>
                        <div class="flex-grow-1">Period of Production</div>
                        <div class="flex-grow-1">Issuer</div>
                      </div>
                      <div class="right_item fs12 flex-box flex-center">
                        <div class="pertwenty">{{ item.From_Certificate_ID }}</div>
                        <div class="pertwenty">{{ item.To_Certificate_ID }}</div>
                        <div class="flex-grow-1">{{ item.Number_of_Certificates }}</div>
                        <div class="flex-grow-1">{{ item.Offset_Attributes }}</div>
                        <div class="flex-grow-1">
                          {{ item.BeginPurchaseDate }} - {{ item.EndPurchaseDate }}
                        </div>
                        <div class="flex-grow-1"></div>
                      </div>
                    </div>
                    <div class="notes fs12 flex-grow-1">
                      <div class="fs16 pb15">Auditor Notes</div>
                      <div class="pb15 notes_detail">
                        This statement is proof of the secure and unique redemption of the
                        l-RECs stated above for the named beneficiary to be reported against
                        consumption in the country during the reporting year stated. 1-RECs
                        are assigned to a beneficiary atredemption and cannot be further
                        assigned to a third party.No other use of these l-RECs is valid
                        under the l-RECStandard.
                      </div>
                      <div class="pb15 notes_detail">
                        Where offset attributes are incthe device registrantwho exclusively
                        holds the environmental attribute rights,has undertaken never to
                        release carbon offsets in association with these MWh;excmeans carbon
                        offsets relating to theseMWh may be traded independently at some
                        point in the future.
                      </div>
                      <div class="pb15 notes_detail">
                        For labelling scheme information please refer to the schemes
                        website.Labelling scheme listing may not be exhaustive.Thermal plant
                        emit carbon as part of the combustion process.Whilst this is not
                        zero carbon it is generally recognisedas carbon neutral where the
                        sourceis recent biomass
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt30 pb20 flex-box flex-between">
                <template v-if="item.Certificate">
                  <div class="flex-grow-1 cer_title multi-line fs22">IREC证书_{{ item.Certificate.companyName ||
                    '' }}_{{ item.Number_of_Certificates }}</div>
                </template>
                <template v-else>
                  <div class="flex-grow-1 cer_title multi-line fs22">IREC证书_{{ item.Number_of_Certificates }}</div>
                </template>
                <a href="javascript:;" class="download fs16" @click="downLoadFile(index)">下载证书</a>
              </div>
              <!-- <div class="multi-line col9">
              一句话简述证书的重要性以及作用一句话简述证书的重要性以及作用一句话简述证书的重要性以及作用
            </div> -->
            </li>
            <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
            <!-- <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination> -->
          </ul>
        </div>

      </div>
    </div>
    <Footer></Footer>


  </div>
</template>
<script>
import Pagination from "../components/pagination.vue";
import vueQr from "vue-qr";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import cookie from "vue-cookies";
export default {
  components: {
    Pagination,
    swiper,
    swiperSlide,
    vueQr,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      url: this.global.certificateUrl,
      oss: this.global.uploadOss,
      htmlTitle: "我的证书",
      cert_list: [],
      pageSize: 15, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数
      isShowNavBarLoading: true, // 是否加载完
      detail: {
        SysCompanyInfo: {},
      },
      bannerList: [],
      is_load: false, //是否加载完毕
      settingType: ''
    };
  },

  // 页面渲染完执行
  created() {
    let _that = this;
    // 获取抬头
    _that.get_user_info();
    _that.setting();
    _that.get_banner();
  },
  methods: {

    //下载证书
    downLoadFile(index) {
      let that = this;
      let list = that.cert_list;
      that.detail = list[index];
      if (list[index].certName != null && list[index].certName != '') {
        var down_url = that.oss + list[index].certName
        window.open(down_url, "_blank");
        that.downFile(down_url)
        return;
      } else {
        let url = that.global.downloadPdf
        // var code = escape('环保桥' + list[index].tradeId)
        // var down_url = url + '?code=' + code
        var down_url = url + '?code=' + list[index].tradeId
        window.open(down_url, "_blank");
      }
    },

    //直接下载pdf
    downFile(url) {
      const elink = document.createElement("a");
      elink.style.display = "none";
      elink.href = url + '?response-content-type=application/octet-stream';
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    },

    get_user_info() {
      let that = this;
      let user = cookie.get("huanbaoqiao_userinfo");
      let token = cookie.get("huanbaoqiao_token");
      if (!user || !token) {
        this.$message.error('请先登录');
        this.$router.push("/login")
        return;
      }
      that.$api.apiUserInfo({ deviceType: '1', id: user.id ? user.id : 0 }).then((res) => {
        if (res.resCode == 0) {
          if (res.root[0].avatar != null && res.root[0].avatar != '') {
            that.oss_avatar = res.root[0].avatar
          }
          cookie.set("huanbaoqiao_userinfo", res.root[0])
        } else if (res.resCode == 401) {
          this.$message.error('请先登录');
          this.$router.push("/login")
          return;
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_list();
    },

    //获取API模式
    setting() {
      let that = this
      let data = {
        tag: 'FNCZOL4XLKZJ',
        pkg_children: 'com.dazz.business.model.setting.CommonSetting'
      }
      that.$api.apiPostSetting(data).then((res) => {
        if (res.resCode == 0) {
          if (res.root.length > 0) {
            that.settingType = res.root[0]
          }
          that.get_list();
        } else {
          that.$message.error(res.resMsg);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    get_list(s_param = {}) {
      var that = this;
      let page = that.pageNum;
      s_param.page = page;
      s_param.limit = that.pageSize;
      s_param.sortProp = 'id',
      s_param.sortType = 'DESC'
      let setting = that.settingType
        // 0是非api模式，1:api模式
      if (setting.settingType == 0) {
        this.$api.notApitradeCertificate(s_param).then((data_res) => {
          if (data_res.resCode == 0) {
            that.is_load = true;
            if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
              that.isShowNavBarLoading = true;
            } else {
              // 判断 是否 加载完毕
              var is_data = true;
              var totalCount = data_res.totalCount;
              var show_total = that.pageNum * that.pageSize;
              if (totalCount > show_total) {
                //当只有一页的时候展示
                is_data = true;
              } else {
                is_data = false;
              }
              that.total = totalCount;
              that.cert_list = data_res.root;
              that.isShowNavBarLoading = is_data;
            }
          } else {
            that.is_load = true;
            // that.$message.error(data_res.msg);
          }
        });
      } else {
        that.$api.tradeCertificate(s_param).then((data_res) => {
          if (data_res.resCode == 0) {
            that.is_load = true;
            if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
              that.isShowNavBarLoading = true;
            } else {
              // 判断 是否 加载完毕
              var is_data = true;
              var totalCount = data_res.totalCount;
              var show_total = that.pageNum * that.pageSize;
              if (totalCount > show_total) {
                //当只有一页的时候展示
                is_data = true;
              } else {
                is_data = false;
              }
              that.total = totalCount;
              that.cert_list = data_res.root;
              that.isShowNavBarLoading = is_data;
            }
          } else {
            that.is_load = true;
            // that.$message.error(data_res.msg);
          }
        });
      }

    },

    //获取banner
    get_banner() {
      let s_data = {
        contentKeys: "CERTIFICATE_PC",
      };
      this.$api.apiIndexBanner(s_data).then((data_res) => {
        //console.log(data_res)
        if (data_res.resCode == 0) {
          let data = data_res.root[0];
          if (data.pictureUrl) {
            data.pictureUrl = data.pictureUrl.split(",");
          }
          this.bannerList = data.pictureUrl;
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },
  },
};
</script>
<style scoped>
.swiper-container {
  height: 40vh;
}

.swiper-container img {
  object-fit: cover;
  -o-object-fit: cover;
  height: 100%;
}
</style>